/**
 * Header Layout
 *
 * @file header.js
 */


import $ from "jquery";
const header = (() => {
  const fixedHeader = () => {
    const search = document.querySelector('.search');
    const searchMobile = document.querySelector('.search-mobile');
    const searchArea = document.querySelector('.site-search-wrapper');
    const searchAreaBox = document.querySelector('#query');
    search.addEventListener('click', function(){
      searchArea.classList.toggle('active');
      searchAreaBox.focus();
    });
    searchMobile.addEventListener('click', function(){
      searchArea.classList.toggle('active');
      searchAreaBox.focus();
    });

    searchArea.addEventListener('click', function(event) {
      if(!event.target.closest('.site-search')) {
        searchArea.classList.toggle('active')
      }
    })

    const goDown = document.querySelector('.go-down');
    const goDownDiv = document.querySelector(".milestones");
    if(goDown) {
      goDown.addEventListener('click', function() {
        window.scroll({
          top: goDownDiv.getBoundingClientRect().top,
          behavior: 'smooth'  // 👈 
        });
      })
    }
    const goDownProduct = document.querySelector('.product-down');
    const goDownProductDiv = document.querySelector(".product-left-right-bg");
    if(goDownProduct) {
      goDownProduct.addEventListener('click', function() {
        window.scroll({
          top: goDownProductDiv.getBoundingClientRect().top - 120,
          behavior: 'smooth'  // 👈 
        });
      })
    }
  };

  const mobileMenu = () => {
    const menuIcon = document.querySelector('.mobile-menu-icon');
    const mobileNav = document.querySelector('.header-links');
    menuIcon.addEventListener('click', function(e) {
      e.currentTarget.classList.toggle('active');
      mobileNav.classList.toggle('active');
    })
  }

  const mobileMenuAcc = () => {
    const menuItem = document.querySelectorAll('.main-nav__item');
    menuItem.forEach((item, index) => {
      item.addEventListener('click', (e) => {
        e.currentTarget.classList.toggle('js-active')
      })
    })
  }

  const copyUrl = () => {
    $(".copyurl").on("click", function(){
      const copyVal= $("#copyVal");
      console.log(copyVal)
      // copyVal.focus(); 
      copyVal.select();
      document.execCommand("copy");
    })
  }

  const init = () => {
    fixedHeader();
    mobileMenu();
    mobileMenuAcc();
    copyUrl();
  };

  return {
    init,
  };
})();

export default header;
