/**
 * Slider Component
 *
 * @file slider.js
 */

import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectCoverflow, EffectFade, Controller } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow, EffectFade, Controller]);

import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.js';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import $ from "jquery";



let windowWidth = window.innerWidth;

const slider = (() => {
  /**
   * fullSlider
   * Full width slider settings
   */
  const fullSlider = () => {
    let swiper2 = new Swiper('.main-slider', {
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: ".hm-next",
        prevEl: ".hm-prev ",
      },
      autoplay: {
        delay: 4000,
      },
      speed:1200,
      noSwiping:true,
      allowTouchMove:false,
      watchSlidesProgress: true,
      loop:false,
      effect: "fade",
      breakpoints: {
        0: {
          noSwiping:false,
          allowTouchMove:true,
          calculateHeight:true,
          autoHeight:true,
        },
        680: {
          noSwiping:false,
          allowTouchMove:true,
          calculateHeight:true,
          autoHeight:true,
        },
        992: {
          noSwiping:true,
          allowTouchMove:false,
        },
        1000: {
          calculateHeight:false,
          autoHeight:false,
        },
      }
    });
    let swiper2Thumb = new Swiper(".swiper-tab-slide", {
      slidesPerView: 4,
      centeredSlides: true,
      slideToClickedSlide: true,
      loop:false, 
      speed:1200,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      autoplay: {
        delay: 4000,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        680: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 4,
        },
      }
    });

    // swiper2Thumb.on('slideChange', function () {
    //   swiper2.slideTo(swiper2Thumb.activeIndex);
    // });

    // swiper2.on('slideChange', function () {
    //   swiper2Thumb.slideTo(swiper2.activeIndex);
    // });

    

    // document.addEventListener('mouseenter', event => {
    //   const el = event.target;
    //   if (el && el.matches && el.matches('.main-slider .swiper-container')) {
    //     // console.log('mouseenter');
    //     // console.log('autoplay running', swiper.autoplay.running);
    //     el.swiper.autoplay.stop();
    //     el.classList.add('swiper-paused');
        
    //     const activeNavItem = el.querySelector('.main-slider .swiper-pagination-bullet-active');
    //     activeNavItem.style.animationPlayState="paused";
    //   }
    // }, true);
    
    // document.addEventListener('mouseleave', event => {
    //   // console.log('mouseleave', swiper.activeIndex, swiper.slides[swiper.activeIndex].progress);
    //   // console.log('autoplay running', swiper.autoplay.running);
    //   const el = event.target;
    //   if (el && el.matches && el.matches('.main-slider .swiper-container')) {
    //     el.swiper.autoplay.start();
    //     el.classList.remove('swiper-paused');
    
    //     const activeNavItem = el.querySelector('.main-slider .swiper-pagination-bullet-active');
        
    //     activeNavItem.classList.remove('swiper-pagination-bullet-active');
    //     // activeNavItem.style.animation = 'none';
    
    //     setTimeout(() => {
    //       activeNavItem.classList.add('swiper-pagination-bullet-active');
    //       // activeNavItem.style.animation = '';
    //     }, 10);
    //   }
    // }, true);
  };

  const homeMilestonesCarousel = () => {
    const milestonesDate = new Swiper('.milestones-top-carousel', {
        slidesPerView: 5,
        spaceBetween: 30,
        centeredSlides:true,
        watchSlidesProgress: true,
        speed:700,
        loop:true,
        freeMode: true,
        freeModeMomentum: false,
        allowTouchMove: false,
        breakpoints: {
          0: {
            slidesPerView: 2,
            centeredSlides:false,
            allowTouchMove: true,
            loop:false,
          },
          680: {
            slidesPerView: 3,
            centeredSlides:true,
          },
          992: {
            slidesPerView: 3,
            allowTouchMove: false,
            loop:true,
          },
          1000: {
            slidesPerView: 4,
          },
          1100: {
            slidesPerView: 5,
          },
        }
    });

    const milestonesImgText = new Swiper('.milestones-img-text', {
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides:true,
        loop:true,
        watchSlidesProgress: true,
        speed:700,
        freeMode: false,
        freeModeMomentum: false,
        allowTouchMove: false,
        breakpoints: {
          0: {
            slidesPerView: 1,
            allowTouchMove: true,
            freeMode: false,
            loop:false,
          },
          992: {
            slidesPerView: 2,
            allowTouchMove: false,
            freeMode: true,
            loop:false,
          },
          1280: {
            slidesPerView: 3,
          },
        }
    });
    $(".milestones-t-prev").on("click", function(){
      milestonesDate.slidePrev();
      milestonesImgText.slidePrev();
    });
    $(".milestones-t-next").on("click", function(){
      milestonesDate.slideNext();
      milestonesImgText.slideNext();
    });
    if (windowWidth < 992) {
      milestonesDate.on('slideChange', function () {
        milestonesImgText.slideTo(milestonesDate.activeIndex);
      });

      milestonesImgText.on('slideChange', function () {
        milestonesDate.slideTo(milestonesImgText.activeIndex);
      });
    }
  }

  const homeNewsSlider = () => {
    let largeSliders = document.querySelectorAll('.news-accordion-carousel')
    let prevArrow = document.querySelectorAll('.section-title .home-news-prev')
    let nextArrow = document.querySelectorAll('.section-title .home-news-next')
    largeSliders.forEach((slider, index)=>{
      // this bit checks if there's more than 1 slide, if there's only 1 it won't loop
      let sliderLength = slider.children[0].children.length
      let result = (sliderLength > 1) ? true : false
      const swiper = new Swiper(slider, {
        slidesPerView: 4,
        spaceBetween: 30,
        navigation: {
          nextEl: nextArrow[index],
          prevEl: prevArrow[index],
        },
        breakpoints: {
          0: {
            slidesPerView: 1.2,
            freeMode: false,
          },
          680: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
          1800: {
            slidesPerView: 4,
          },
        }
      });	
    });
  }

  const homeNewsSlider2 = () => {
    const homeNewsSlider2 = new Swiper('.news-accordion-carousel2', {
      slidesPerView: 4,
      spaceBetween: 30,
      navigation: {
        nextEl: ".news-tab-filter .home-news-next",
        prevEl: ".news-tab-filter .home-news-prev ",
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          freeMode: false,
        },
        680: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
        1800: {
          slidesPerView: 4,
        },
      }
    });
    $(".news-tab-filter .tab-link-a").on("click", function(){
      
      setTimeout(function() {
        const homeNewsSlider2 = new Swiper('.news-accordion-carousel2', {
          slidesPerView: 4,
          spaceBetween: 30,
          navigation: {
            nextEl: ".news-tab-filter .home-news-next",
            prevEl: ".news-tab-filter .home-news-prev ",
          },
          breakpoints: {
            0: {
              slidesPerView: 1.2,
              freeMode: false,
            },
            680: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
            1800: {
              slidesPerView: 4,
            },
          }
        });
      }, 100);
    });
  }

  const homeNewsSlider3 = () => {
    const homeNewsSlider3 = new Swiper('.news-accordion-carousel3', {
      slidesPerView: 4,
      spaceBetween: 30,
      navigation: {
        nextEl: ".news-tab-filter .home-news-next",
        prevEl: ".news-tab-filter .home-news-prev ",
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          freeMode: false,
        },
        680: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
        1800: {
          slidesPerView: 4,
        },
      }
    });
    $(".tab-link-a.tblnk2").on("click", function(){
      
      setTimeout(function() {
        const homeNewsSlider3 = new Swiper('.news-accordion-carousel3', {
          slidesPerView: 4,
          spaceBetween: 30,
          navigation: {
            nextEl: ".news-tab-filter .home-news-next",
            prevEl: ".news-tab-filter .home-news-prev ",
          },
          breakpoints: {
            0: {
              slidesPerView: 1.2,
              freeMode: false,
            },
            680: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
            1800: {
              slidesPerView: 4,
            },
          }
        });
      }, 100);
    });
  }

  const producSlider = () => {
    const swiper = new Swiper('.product-slider', {
        slidesPerView: 4,
        spaceBetween: 30,
        freeMode: true,
        navigation: {
          nextEl: ".strength-next",
          prevEl: ".strength-prev ",
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            freeMode: false,
          },
          680: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
          1800: {
            slidesPerView: 4,
          },
        }
    });
    const argeSwiper = new Swiper('.arge-slider', {
        slidesPerView: 4,
        spaceBetween: 40,
        freeMode: true,
        navigation: {
          nextEl: ".strength-next",
          prevEl: ".strength-prev ",
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            freeMode: false,
          },
          680: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
          1800: {
            slidesPerView: 4,
          },
        }
    });
  }

  const prDetailCarousel = () => {
    const prDetailCarousel = new Swiper('.pr-detail-carousel-list', {
        slidesPerView: 5,
        spaceBetween: 65,
        freeMode: true,
        navigation: {
          nextEl: ".arr-prd.next-arrow",
          prevEl: ".arr-prd.prev-arrow",
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            freeMode: false,
          },
          680: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
          1800: {
            slidesPerView: 5,
          },
        }
    });
  }

  const historySlider = () => {
    
    let swiperHistory = new Swiper(".mySwiper2", {
      direction: 'horizontal',
      speed: 1000,
      effect: 'coverflow',
      centeredSlides: true,
      grabCursor: true,
      slidesPerView: 'auto',
      mousewheel: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 30,
        depth: 400,
        modifier: 2,
        slideShadows: false,
      },

      breakpoints: {
        1200: {
          direction: 'vertical'
        },
      }
    });

    let swiperHistoryThumb = new Swiper(".mySwiper", {
      direction: 'horizontal',
      slidesPerView: 1,
      centeredSlides: true,
      slideToClickedSlide: true,
      speed: 500,
      spaceBetween: 20, 
      navigation: {
        nextEl: ".history-next",
        prevEl: ".history-prev",
      },
      breakpoints: {
        1200: {
          direction: 'vertical',
          autoHeight: true,
        },
        1610: {
          autoHeight: false,
        },
      }

      
    });

    swiperHistoryThumb.on('slideChange', function () {
      swiperHistory.slideTo(swiperHistoryThumb.activeIndex);
    });

    swiperHistory.on('slideChange', function () {
      swiperHistoryThumb.slideTo(swiperHistory.activeIndex);
    });

    //swiperHistoryThumb[0].controller.control = swiperHistory;
  }

  const aboutRightPercentSlide = () => {
    
    let aboutRightPercentSlide = new Swiper(".right-percent-carousel", {
      spaceBetween: 60,
      slidesPerView: 1.7,
      initialSlide: 1,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 1.5,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 1.3,
          spaceBetween: 30,
        },
        1480: {
          slidesPerView: 1.5,
          spaceBetween: 40,
        },
      },
    });

  }

  const countryCarousel = () => {
    
    let countryCarousel = new Swiper(".country-carousel-slide", {
      spaceBetween: 0,
      slidesPerView: 7,
      initialSlide: 4,
      loop: true,
      speed: 2000,
      autoplay: {
        delay: 0.1,
        disableOnInteraction: false,
      },
      direction: 'vertical',
      centeredSlides: true,
      freeMode: true,
      freeModeMomentum: false,
      allowTouchMove: false,
      breakpoints: {
        0: {
          slidesPerView: 5,
        },
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 4,
          initialSlide: 2,
        },
        1100: {
          slidesPerView: 7,
          initialSlide: 4,
        }
      },
    });
  }

  const strengthCarousel = () => {
    
    let strengthCarousel = new Swiper(".our-strength-wrapper", {
      slidesPerView: 4,
      spaceBetween: 30,
      slidesPerGroup:4,
      navigation: {
        nextEl: ".strength-next",
        prevEl: ".strength-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          slidesPerGroup:1,
        },
        680: {
          slidesPerView: 2,
          slidesPerGroup:2,
        },
        1024: {
          slidesPerView: 3,
          slidesPerGroup:3,
        },
        1200: {
          slidesPerView: 4,
          slidesPerGroup:4,
        }
      },
    });
  }
  const infographicCarousel = () => {
    if (windowWidth < 992) {
      const rdClassName = document.getElementsByClassName("infographic__item");
      for (let i = 0; i < rdClassName.length; i += 1) {
        rdClassName[i].classList.add('swiper-slide');
      }
      let infographicCarousel = new Swiper(".info-over.rd-center", {
        spaceBetween: 0,
        slidesPerView: 2.1,
        loop: true,
        infinite: true,
        centeredSlides: true,
        slideToClickedSlide: true,
        navigation: {
          nextEl: ".info-arrow-next",
          prevEl: ".info-arrow-prev ",
        },
      });

      let infographicCarousel2 = new Swiper(".info-over.hmk-center", {
        spaceBetween: 0,
        slidesPerView: 2.1,
        loop: true,
        infinite: true,
        centeredSlides: true,
        slideToClickedSlide: true,
        navigation: {
          nextEl: ".info-arrow-next",
          prevEl: ".info-arrow-prev ",
        },
      });
    }
  }
  if (windowWidth < 992){
    let infoAllWidth = document.getElementsByClassName("infographic__item");
    setTimeout(function(){
      for (let ik = 0; ik < infoAllWidth.length; ik += 1) {
        infoAllWidth[ik].style.height=infoAllWidth[ik].offsetWidth + "px";
      }
    }, 1000);
  }


  const numberInfoCarousel = () => {
    if (windowWidth < 680) {
      let numberInfoCarousel = new Swiper(".info-number-mobile-carousel", {
        slidesPerView: 1.5,
        spaceBetween: 15,
        centeredSlides:true,
        loop:true,
        navigation: {
          nextEl: ".number-arrow-next",
          prevEl: ".number-arrow-prev ",
        },
      });
      // let numberAllWidth = document.getElementsByClassName("info-number-item");
      // setTimeout(function(){
      //   for (let iks = 0; iks < numberAllWidth.length; iks += 1) {
      //     numberAllWidth[iks].style.height=numberAllWidth[iks].offsetWidth + "px";
      //   }
      // }, 1000);
    }
  }


  const brandCarousel = () => {
    let brandCarousel = new Swiper(".brands-item-wrapper", {
      spaceBetween: 40,
      slidesPerView: 4.3,
      navigation: {
        nextEl: ".strength-2-next",
        prevEl: ".strength-2-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
        },
        720: {
          slidesPerView: 2.3,
        },
        992: {
          slidesPerView: 3.3,
        },
        1280: {
          slidesPerView: 4.3,
        },
      },
    });
  }
  
  const milestonesCarousel = () => {
    let milestonesCarousel = new Swiper(".milestones-caorusel", {
      speed: 500,
      spaceBetween: 100,
      slidesPerView: 7,
      centeredSlides: true,
      grabCursor: false,
      noSwiping:true,
      allowTouchMove:false,
      loop:false,
      preloadImages: false,
      lazy: true,
      navigation: {
        nextEl: ".milestones-next",
        prevEl: ".milestones-prev",
      },
      breakpoints: {
        0: {
          spaceBetween: 0,
          slidesPerView: 1,
          allowTouchMove:true
        },
        992: {
          spaceBetween: 0,
          slidesPerView: 5,
        },
        1200: {
          spaceBetween: 50,
        },
        1440: {
          slidesPerView: 6,
        },
        1680: {
          slidesPerView: 7,
        }
      },
    });

    let milestonesCarouselText = new Swiper(".milestones-text", {
      slidesPerView: 1,
      centeredSlides: true,
      speed: 500,
      spaceBetween: 0,
      loop:false,
    });

    milestonesCarouselText.on('slideChange', function () {
      milestonesCarousel.slideTo(milestonesCarouselText.activeIndex);
    });

    milestonesCarousel.on('slideChange', function () {
      milestonesCarouselText.slideTo(milestonesCarousel.activeIndex);
    });
  }
  
  const homeSgCarousel = () => {
    let homeSgCarousel1 = new Swiper(".sg-carousel", {
      autoplay: {
        delay: 3000,
      },
      speed:1000,
      effect: "fade",
      noSwiping:true,
      allowTouchMove:false,
      loop:true,
    });

    let homeSgCarousel2 = new Swiper(".sg-carousel-2", {
      autoplay: {
        delay: 3000,
      },
      effect: "fade",
      speed:1000,
      noSwiping:true,
      allowTouchMove:false,
      loop:true,
    });
  }
  const productDetailSlideCl = () => {
    $(".product-slider-th .product-slider-thumb .item").on("click", function(){
      $(".product-slider-th .product-slider-img img").attr("src", $(this).attr("data-img"));
      return false;
    });
  }
  
  const ikCarousel = () => {
    let ikCarousel = new Swiper(".ik-list-carousel", {
      slidesPerView: 4,
      spaceBetween: 30,
      navigation: {
        nextEl: ".ik-next-arrow",
        prevEl: ".ik-prev-arrow",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        680: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        }
      },
    });
  }
  
  const blogTabCarousel = () => {
    if($(".news-tab-filter .news-tab .tab-list a").length >= 7){
      
      let ikCarousel = new Swiper(".blog-list-left .tab-list", {
        slidesPerView: "auto",
        spaceBetween: 0,
        grabCursor: true,
        centeredSlides:true,
        slideToClickedSlide: true,
        initialSlide: 2,
        loop:true
      });
    }
  }

  const init = () => {
    fullSlider();
    producSlider();
    historySlider();
    aboutRightPercentSlide();
    countryCarousel();
    strengthCarousel();
    infographicCarousel();
    brandCarousel();
    milestonesCarousel();
    homeSgCarousel();
    prDetailCarousel();
    productDetailSlideCl();
    numberInfoCarousel();
    ikCarousel();
    homeMilestonesCarousel();
    homeNewsSlider();
    homeNewsSlider2();
    homeNewsSlider3();
    blogTabCarousel();
  };

  return {
    init,
  };
})();

export default slider;
